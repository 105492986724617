import {environmentVars} from './internalTest';
import {Client} from '../../../shared/utilities/type-helpers';
const client: Client = {clientType: 'terrific', homePageFeedType: 'stores'};
export const environment = {
  ...environmentVars,
  firebaseConfig: {
    apiKey: 'AIzaSyACtk4nQIkvs91acKg5B9b0V3y4qW8tVGM',
    authDomain: 'staging.terrific.live',
    projectId: 'terrific-deploy',
    storageBucket: 'terrific-deploy.appspot.com',
    messagingSenderId: '871100133386',
    appId: '1:871100133386:web:ceda850f6d2bab12a5eff3',
    measurementId: 'G-PPHLZ2C8SE',
  },
  traceOrigin: {
    name: 'terrific-staging',
    domain: 'staging.terrific.live',
  },
  client,
  cspGuardUrl: 'https://us-central1-terrific-deploy.cloudfunctions.net/cspGuardV2',
  apiGatewayUrl: 'https://terrific-deploy-b46ej0ne.uc.gateway.dev',
};
